exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bestall-valsedlar-tsx": () => import("./../../../src/pages/bestall-valsedlar.tsx" /* webpackChunkName: "component---src-pages-bestall-valsedlar-tsx" */),
  "component---src-pages-bli-medlem-tsx": () => import("./../../../src/pages/bli-medlem.tsx" /* webpackChunkName: "component---src-pages-bli-medlem-tsx" */),
  "component---src-pages-donera-tsx": () => import("./../../../src/pages/donera.tsx" /* webpackChunkName: "component---src-pages-donera-tsx" */),
  "component---src-pages-eu-val-tsx": () => import("./../../../src/pages/eu-val.tsx" /* webpackChunkName: "component---src-pages-eu-val-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-kandidaturer-tsx": () => import("./../../../src/pages/kandidaturer.tsx" /* webpackChunkName: "component---src-pages-kandidaturer-tsx" */),
  "component---src-pages-kyrkovalskollekten-tsx": () => import("./../../../src/pages/kyrkovalskollekten.tsx" /* webpackChunkName: "component---src-pages-kyrkovalskollekten-tsx" */),
  "component---src-pages-medlem-tsx": () => import("./../../../src/pages/medlem.tsx" /* webpackChunkName: "component---src-pages-medlem-tsx" */),
  "component---src-pages-partistyrelsen-tsx": () => import("./../../../src/pages/partistyrelsen.tsx" /* webpackChunkName: "component---src-pages-partistyrelsen-tsx" */),
  "component---src-pages-politik-tsx": () => import("./../../../src/pages/politik.tsx" /* webpackChunkName: "component---src-pages-politik-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-forslag-redirect-tsx": () => import("./../../../src/templates/ForslagRedirect.tsx" /* webpackChunkName: "component---src-templates-forslag-redirect-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/Program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */),
  "component---src-templates-senaste-nytt-tsx": () => import("./../../../src/templates/senaste-nytt.tsx" /* webpackChunkName: "component---src-templates-senaste-nytt-tsx" */)
}

